<script>
import Vertical from '@/layouts/vertical/vertical'
import { mapGetters } from 'vuex'
import CommonAlert from '@/components/commons/common-alert'
import CommonLoading from '@/components/commons/common-loading'
export default {
  components: {
    CommonLoading,
    Vertical,
    CommonAlert,
  },
  computed: {
    ...mapGetters('alert', ['items', 'keys']),
    ...mapGetters('loading', ['loading', 'manualLoading', 'disableLoading']),
  },
  methods: {
    getAlertItem(key) {
      return this.items.filter((item) => item.key === key)[0]
    },
  },
}
</script>

<template>
  <div>
    <common-alert
      v-for="(key, index) in keys"
      :key="key"
      :type="getAlertItem(key).type"
      :messages="getAlertItem(key).messages"
      :index="index"
    />
    <common-loading :loading="!disableLoading && (loading || manualLoading)" />
    <Vertical>
      <slot />
    </Vertical>
  </div>
</template>
