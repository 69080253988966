export const menuItems = {
  MT_SQUARE: [
    {
      id: 1,
      label: 'menuitems.homepage.text',
      icon: 'bx bx-home-circle',
      link: '/dashboard',
    },
    {
      id: 2,
      label: 'menuitems.saas.text',
      icon: 'bx bx-briefcase',
      link: '/management-service',
    },
    {
      id: 3,
      label: 'menuitems.packages.text',
      icon: 'bx bx-paper-plane',
      link: '/package-information-management',
    },
    {
      id: 4,
      label: 'menuitems.customer.text',
      icon: 'bx bxs-user-detail',
      link: '/management-member',
    },
    {
      id: 5,
      label: 'menuitems.role.text',
      icon: 'bx bx-task',
      link: '/internal-member-management',
    },
    {
      id: 6,
      label: 'menuitems.settings.subItems-1.text',
      icon: 'bx bx-aperture',
      link: '/mt-account-setting',
    },
  ],
  CUSTOMER_MT: [
    {
      id: 1,
      label: 'menuitems.homepage.text',
      icon: 'bx bx-home-circle',
      link: '/customer/dashboard',
    },
    {
      id: 2,
      label: 'menuitems.customer-manage.text',
      icon: 'bx bxs-user-detail',
      link: '/customer/',
      subItems: [
        {
          parentId: 2,
          id: 3,
          label: 'menuitems.customer-manage.subItems-2.text',
          link: '/customer/employee-management',
        },
        {
          parentId: 2,
          id: 4,
          label: 'menuitems.customer-manage.subItems-4.text',
          link: '/customer/list-of-internal-member',
        },
      ],
    },
    {
      id: 5,
      label: 'menuitems.settings.text',
      icon: 'bx bx-aperture',
      link: '/customer/',
      subItems: [
        {
          parentId: 5,
          id: 6,
          label: 'menuitems.settings.subItems-2.text',
          link: '/customer/setting-info-company',
        },
        {
          parentId: 5,
          id: 7,
          label: 'menuitems.settings.subItems-3.text',
          link: '/customer/account-setting',
        },
      ],
    },
    {
      id: 8,
      label: 'menuitems.support.text',
      icon: 'bx bx-headphone',
      externalLink: true,
      link: 'https://mt-square.co.jp/contact',
    },
  ],
  CUSTOMER_ADMIN: [
    {
      id: 1,
      label: 'menuitems.homepage.text',
      icon: 'bx bx-home-circle',
      link: '/customer/dashboard',
    },
    {
      id: 2,
      label: 'menuitems.saas.text',
      icon: 'bx bx-briefcase',
      link: '/customer/',
      subItems: [
        {
          parentId: 2,
          id: 3,
          label: 'menuitems.saas.subItems-1.text',
          link: '/customer/saas-management',
        },
        {
          parentId: 2,
          id: 4,
          label: 'menuitems.saas.subItems-2.text',
          link: '/customer/contract-list',
        },
        {
          parentId: 2,
          id: 5,
          label: 'menuitems.saas.subItems-3.text',
          link: '/customer/list-of-cost',
        },
      ],
    },
    {
      id: 6,
      label: 'menuitems.customer-manage.text',
      icon: 'bx bxs-user-detail',
      link: '/customer/',
      subItems: [
        {
          parentId: 6,
          id: 7,
          label: 'menuitems.customer-manage.subItems-1.text',
          link: '/customer/department-list',
        },
        {
          parentId: 6,
          id: 8,
          label: 'menuitems.customer-manage.subItems-3.text',
          link: '/customer/management-job',
        },
        {
          parentId: 6,
          id: 9,
          label: 'menuitems.customer-manage.subItems-2.text',
          link: '/customer/employee-management',
        },
        {
          parentId: 6,
          id: 10,
          label: 'menuitems.customer-manage.subItems-4.text',
          link: '/customer/list-of-internal-member',
        },
      ],
    },
    {
      id: 11,
      label: 'menuitems.settings.text',
      icon: 'bx bx-aperture',
      link: '/customer/',
      subItems: [
        {
          parentId: 11,
          id: 12,
          label: 'menuitems.settings.subItems-2.text',
          link: '/customer/setting-info-company',
        },
        {
          parentId: 11,
          id: 13,
          label: 'menuitems.settings.subItems-3.text',
          link: '/customer/account-setting',
        },
      ],
    },
    {
      id: 15,
      label: 'menuitems.support.text',
      icon: 'bx bx-headphone',
      externalLink: true,
      link: 'https://mt-square.co.jp/contact',
    },
  ],
}
