<template>
  <div
    class="MTHamburger"
    @mouseenter="onHover(true)"
    @mouseleave="onHover(false)"
    @click="onClick"
  >
    <div :class="`hamburger hamburger-slice ${classHover}`"></div>
  </div>
</template>
<script>
export default {
  name: 'MTHamburger',
  props: {
    onClick: {
      type: Function,
      required: true,
    },
    isCollapsed: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isHover: false,
    }
  },
  computed: {
    classHover() {
      if (!this.isHover) {
        return ''
      }
      return this.isCollapsed ? 'hamburger-right' : 'hamburger-left'
    },
  },
  methods: {
    onHover(val) {
      this.isHover = val
    },
  },
}
</script>
