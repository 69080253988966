<script>
import router from '@/router'
import NavBar from '@/layouts/vertical/nav-bar'
import SideBar from '@/layouts/vertical/side-bar'

/**
 * Vertical layout
 */
export default {
  components: { NavBar, SideBar },
  data() {
    return {
      isMenuCondensed: false,
    }
  },
  methods: {
    toggleMenu() {
      document.body.classList.toggle('sidebar-enable')

      if (window.screen.width >= 992) {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove('sidebar-enable')
          document.body.classList.remove('vertical-collpsed')
        })
        document.body.classList.toggle('vertical-collpsed')
      } else {
        // eslint-disable-next-line no-unused-vars
        router.afterEach((routeTo, routeFrom) => {
          document.body.classList.remove('sidebar-enable')
        })
        document.body.classList.remove('vertical-collpsed')
      }
      this.isMenuCondensed = !this.isMenuCondensed
    },
    toggleRightSidebar() {
      document.body.classList.toggle('right-bar-enabled')
    },
  },
}
</script>

<template>
  <div>
    <div id="layout-wrapper">
      <NavBar :is-collapsed="isMenuCondensed" />
      <SideBar :is-condensed="isMenuCondensed" />
      <!-- ============================================================== -->
      <!-- Start Page Content here -->
      <!-- ============================================================== -->

      <div class="main-content">
        <div class="page-content">
          <!-- Start Content-->
          <div class="container-fluid">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
