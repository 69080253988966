<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="logo-horizonal">
          <img :src="mtLogoHorizontal" alt="logo Mt.SQUARE" @click="moveToDashBoard" />
        </div>
        <div class="logo-mt">
          <img :src="mtLogo" alt="logo Mt.SQUARE" @click="moveToDashBoard" />
        </div>
        <hamburger :is-collapsed="isCollapsed" :on-click="toggleMenu" />
      </div>

      <div class="d-flex">
        <b-dropdown right variant="black" toggle-class="header-item" menu-class="dropdown-menu-end">
          <template #button-content>
            <img
              class="rounded-circle header-profile-user"
              :src="currentUser.avatar || defaultNavBarImage"
              alt="Header Avatar"
            />
            <span class="ms-1">{{ currentUser.name }}</span>
            <em class="bx bx-chevron-down" />
          </template>
          <b-dropdown-item @click.prevent="moveToAccount">
            <div class="dropdown-item">
              <img
                src="@/assets/icons/ic_account.svg"
                alt="account-icon"
                class="dropdown-item__icon"
              />
              <span>アカウント設定</span>
            </div>
          </b-dropdown-item>
          <b-dropdown-item v-if="switchLoginEnable" @click.prevent="switchAccess">
            <div class="dropdown-item">
              <img
                src="@/assets/icons/ic_switch.svg"
                alt="switch-login-icon"
                class="dropdown-item__icon"
              />
              <span>{{ switchLabel }}</span>
            </div>
          </b-dropdown-item>
          <b-dropdown-item @click.prevent="logoutUser">
            <div class="dropdown-item text-danger">
              <img
                src="@/assets/icons/ic_logout.svg"
                alt="logout-icon"
                class="dropdown-item__icon dropdown-item__icon-logout"
              />
              <span class="text-danger">ログアウト</span>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>
<script>
import MT from '@/assets/images/logo-mt/mt-logo-vertical.png'
import MT_2 from '@/assets/images/logo-mt/mt-logo-horizontal.png'
import Hamburger from '@/components/hamburger'
import { mapGetters, mapActions } from 'vuex'
import { DEFAULT_NAVBAR_PROFILE_IMAGE, SWITCH_LOGIN_LABEL } from '@/utils/constants'

/**
 * Nav-bar Component
 */
export default {
  name: 'NavBar',
  components: { Hamburger },
  props: {
    isCollapsed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      mtLogo: MT,
      mtLogoHorizontal: MT_2,
      defaultNavBarImage: DEFAULT_NAVBAR_PROFILE_IMAGE,
    }
  },
  computed: {
    ...mapGetters('user', ['currentUser', 'isSystemUser', 'switchLoginEnable', 'isSwitched']),
    switchLabel() {
      if (this.isSwitched) {
        return SWITCH_LOGIN_LABEL.SYSTEM_LOGIN
      }
      return SWITCH_LOGIN_LABEL.CUSTOMER_LOGIN
    },
  },
  methods: {
    ...mapActions('user', ['logout', 'switchLogin']),
    toggleMenu() {
      this.$parent.toggleMenu()
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar()
    },
    logoutUser() {
      this.logout()
    },
    moveToAccount() {
      let routeName = 'mt-account-setting'
      if (!this.isSystemUser || (this.switchLoginEnable && this.isSwitched)) {
        routeName = 'account-setting'
      }
      this.$router.push(
        {
          name: routeName,
        },
        () => {
          return {}
        }
      )
    },
    moveToDashBoard() {
      let routeName = 'dashboard'
      if (!this.isSystemUser || (this.switchLoginEnable && !this.isSwitched)) {
        routeName = 'dashboard-customer'
      }
      this.$router.push(
        {
          name: routeName,
        },
        () => {
          return {}
        }
      )
    },
    switchAccess() {
      this.switchLogin(!this.isSwitched)
      this.$router.push(
        {
          name: !this.isSwitched ? 'dashboard' : 'dashboard-customer',
        },
        () => {
          return {}
        }
      )
    },
  },
}
</script>
