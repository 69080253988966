<template>
  <div ref="alert" :class="getClass(type)" :style="{ top: `${topValue}px` }">{{ messages }}</div>
</template>
<script>
import { ALERT_TYPE } from '@/utils/constants'

export default {
  name: 'Alert',
  props: {
    type: {
      type: Number,
      default: ALERT_TYPE.INFO,
    },
    messages: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      topValue: 0,
    }
  },
  mounted() {
    if (this.index !== 0) {
      this.topValue = this.index * (this.$refs.alert.clientHeight + 16) // 16 = 1rem
    }
  },
  methods: {
    getClass(type) {
      switch (type) {
        case ALERT_TYPE.SUCCESS: {
          return 'MTAlert MTAlert-success'
        }
        case ALERT_TYPE.ERROR: {
          return 'MTAlert MTAlert-error'
        }
        case ALERT_TYPE.WARN: {
          return 'MTAlert MTAlert-warn'
        }
        case ALERT_TYPE.INFO: {
          return 'MTAlert MTAlert-info'
        }
      }
    },
  },
}
</script>
<style lang="scss">
.MTAlert {
  position: fixed;
  top: 0;
  right: 1rem;
  width: 450px;
  z-index: 99999;
  padding: 0.75rem 1.25rem;
  margin-top: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  -webkit-animation-name: run;
  -webkit-animation-duration: 0.5s;
  &-success {
    color: #1f7556;
    background-color: #d6f3e9;
    border-color: #c2eddd;
  }
  &-error {
    color: #924040;
    background-color: #fde1e1;
    border-color: #fcd2d2;
  }
  &-info {
    color: #306391;
    background-color: #dcedfc;
    border-color: #cbe4fb;
  }
  &-warn {
    color: #916c2e;
    background-color: #fcf0db;
    border-color: #fbe9c9;
  }
  @-webkit-keyframes run {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.5;
    }
    75% {
      opacity: 0.75;
    }
    100% {
      opacity: 1;
    }
  }
}
</style>
