<script>
import simplebar from 'simplebar-vue'

import SideNav from './side-nav'

/**
 * Sidebar component
 */
export default {
  components: { simplebar, SideNav },
  props: {
    isCondensed: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      settings: {
        minScrollbarLength: 60,
      },
    }
  },
  watch: {
    $route: {
      handler: 'onRoutechange',
      immediate: true,
      deep: true,
    },
    type: {
      immediate: true,
      handler() {
        document.body.setAttribute('data-sidebar', 'dark')
        document.body.removeAttribute('data-topbar')
        document.body.removeAttribute('data-sidebar-size')
        document.body.removeAttribute('data-keep-enlarged')
        document.body.classList.remove('vertical-collpsed')
      },
    },
    width: {
      immediate: true,
      handler() {
        document.body.setAttribute('data-layout-mode', 'fluid')
        document.body.removeAttribute('data-layout-size')
        document.body.removeAttribute('data-layout-scrollable')
      },
    },
  },
  methods: {
    onRoutechange() {
      setTimeout(() => {
        if (document.getElementsByClassName('mm-active').length > 0) {
          const currentPosition = document.getElementsByClassName('mm-active')[0].offsetTop
          if (currentPosition > 500)
            this.$refs.currentMenu.SimpleBar.getScrollElement().scrollTop = currentPosition + 300
        }
      }, 300)
    },
  },
}
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="vertical-menu">
    <simplebar
      v-if="!isCondensed"
      id="my-element"
      ref="currentMenu"
      :settings="settings"
      class="h-100"
    >
      <SideNav />
    </simplebar>

    <simplebar v-else class="h-100">
      <SideNav />
    </simplebar>
  </div>
  <!-- Left Sidebar End -->
</template>
