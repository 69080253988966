<template>
  <div v-if="loading" class="loading-overlay">
    <div class="donut"></div>
  </div>
</template>

<script>
export default {
  name: 'CommonLoading',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
