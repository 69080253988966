<script>
import { menuItems } from './vertical-menu'
import router from '../../router/index'
import { mapGetters } from 'vuex'

/**
 * Sidenav component
 */
export default {
  data() {
    return {
      menuData: null,
    }
  },
  computed: {
    ...mapGetters('user', [
      'currentUser',
      'isCustomerUser',
      'isCustomerAdmin',
      'isSwitched',
      'switchLoginEnable',
    ]),
    menuItems() {
      if (!this.currentUser) {
        router.push('/404')
      }
      if ((this.switchLoginEnable && this.isSwitched) || this.isCustomerAdmin) {
        return menuItems.CUSTOMER_ADMIN
      }
      if (this.isCustomerUser) {
        return menuItems.CUSTOMER_MT
      }
      return menuItems.MT_SQUARE
    },
  },
  mounted() {
    const links = document.getElementsByClassName('side-nav-link-ref')
    let matchingMenuItem = null
    links.forEach((link) => {
      if (window.location.pathname.indexOf(link['pathname']) !== -1) {
        matchingMenuItem = link
      }
    })
    if (matchingMenuItem) {
      const parent = matchingMenuItem.parentElement
      if (parent) {
        parent.classList.add('mm-active')
        if (parent.parentElement && parent.parentElement.parentElement) {
          parent.parentElement.parentElement.classList.add('mm-active')
          parent.parentElement.parentElement.classList.add('expend')
        }
      }
    }
  },
  methods: {
    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item) {
      return item.subItems !== undefined ? item.subItems.length > 0 : false
    },

    toggleArrow(event) {
      const target = event.currentTarget
      if (target.parentElement) {
        if (target.nextElementSibling.classList.contains('mm-show')) {
          target.nextElementSibling.classList.remove('mm-show')
          target.nextElementSibling.classList.add('mm-collapse')
        } else {
          target.nextElementSibling.classList.add('mm-show')
          target.nextElementSibling.classList.remove('mm-collapse')
        }
        target.parentElement.classList.toggle('collapsed')
      }
    },
  },
}
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->

  <!--- Sidemenu -->
  <div id="sidebar-menu">
    <!-- Left Menu Start -->
    <ul id="side-menu" class="metismenu list-unstyled">
      <template v-for="item in menuItems">
        <li v-if="item.isTitle" :key="item.id" class="menu-title">
          {{ $t(item.label) }}
        </li>
        <li v-if="!item.isTitle && !item.isLayout" :key="item.id">
          <a
            v-if="hasItems(item)"
            href="javascript:void(0);"
            class="is-parent"
            :class="{ 'has-arrow': !item.badge, 'has-dropdown': item.badge }"
            @click.prevent="toggleArrow"
          >
            <i v-if="item.icon" :class="item.icon" aria-hidden="true" />
            <span>{{ $t(item.label) }}</span>
            <span
              v-if="item.badge"
              :class="`badge rounded-pill bg-${item.badge.variant} float-end`"
              >{{ $t(item.badge.text) }}</span
            >
          </a>

          <router-link
            v-if="!hasItems(item) && !item.externalLink"
            :to="item.link"
            class="side-nav-link-ref"
          >
            <i v-if="item.icon" :class="item.icon" aria-hidden="true" />
            <span>{{ $t(item.label) }}</span>
            <span
              v-if="item.badge"
              :class="`badge rounded-pill bg-${item.badge.variant} float-end`"
              >{{ $t(item.badge.text) }}</span
            >
          </router-link>
          <a
            v-if="!hasItems(item) && item.externalLink"
            :href="item.link"
            class="side-nav-link-ref"
            target="_blank"
          >
            <i v-if="item.icon" :class="item.icon" aria-hidden="true" />
            <span>{{ $t(item.label) }}</span>
            <span
              v-if="item.badge"
              :class="`badge rounded-pill bg-${item.badge.variant} float-end`"
              >{{ $t(item.badge.text) }}</span
            >
          </a>

          <ul v-if="hasItems(item)" class="sub-menu mm-show" aria-expanded="false">
            <li v-for="(subitem, index) of item.subItems" :key="index">
              <router-link v-if="!hasItems(subitem)" :to="subitem.link" class="side-nav-link-ref">
                {{ $t(subitem.label) }}
              </router-link>
              <a
                v-if="hasItems(subitem)"
                class="side-nav-link-a-ref has-arrow"
                href="javascript:void(0);"
                @click.prevent="toggleArrow"
                >{{ $t(subitem.label) }}</a
              >
              <ul v-if="hasItems(subitem)" class="sub-menu mm-show" aria-expanded="false">
                <li v-for="(subSubitem, index2) of subitem.subItems" :key="index2">
                  <a
                    v-if="subSubitem.externalLink"
                    :href="subSubitem.link"
                    class="side-nav-link-ref"
                    target="_blank"
                  >
                    {{ $t(subSubitem.label) }}
                  </a>
                  <router-link v-else :to="subSubitem.link" class="side-nav-link-ref">
                    {{ $t(subSubitem.label) }}
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </template>
    </ul>
  </div>
  <!-- Sidebar -->
</template>
